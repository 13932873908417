
import { Show } from "@refinedev/antd";

import { Typography, Form, Row, Button, Col, Input, Checkbox, Divider, Alert, Card, Select, InputNumber, Space } from "antd";
import layout from "antd/es/layout";
import React, { useEffect } from "react";
import { DatePicker } from 'antd';
import { fetchFacturas } from "API/functions";
import { isValidLegalEntityNif, isValidNaturalPersonNif } from "nif-dni-nie-cif-validation";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import Direccion from "components/direccion";
import { MasIcon, MenosIcon } from "components/icons";
import axios from "axios";

const { RangePicker } = DatePicker;


const { Title } = Typography;
export const CrearFactura: React.FC = () => {
    const [form] = Form.useForm();
    const [mensaje, setMensaje] = React.useState<string>("Ningún proceso en curso")
    const [loading, setLoading] = React.useState<boolean>(false)
    const [numero, setNumero] = React.useState<number>(1)
    const [numeroOtros, setNumeroOtros] = React.useState<number>(0)
    const { Option } = Select;
    useEffect(() => {

    }, [])
    dayjs.locale('es');

    function getPayload(): facturacion {
        const formValues = form.getFieldsValue()
        //create envolventes array of type envolvente
        const items: item[] = []
        const otros: otro[] = []
        for (let i = 0; i < numero!; i++) {
            const item = {
                referencia_catastral: formValues["referenciacatastral" + i],
                tipo_edificio: formValues["tipoedificio" + i],
                precio: '' + formValues["precio" + i],
                tasa: '' + formValues["tasa" + i],
                previopost: formValues["previopost" + i],
                tasaprevio: '' + formValues["tasaprevio" + i]
            }
            items.push(item)
        }
        for (let i = 0; i < numeroOtros!; i++) {
            const otro = {
                descripcion: formValues["descripcion" + i],
                precio: '' + formValues["preciootros" + i],
            }
            otros.push(otro)
        }
        const facturacion = {
            razon_social: formValues.razonsocialfacturacion,
            nif: formValues.niffacturacion,
            direccion: formValues.callefacturacion,
            numero: '' + formValues.numerofacturacion,
            cp: '' + formValues.codigopostalfacturacion,
            municipio: formValues.municipiofacturacion,
            provincia: formValues.provinciafacturacion,
            numero_factura: formValues.numerofactura,
            items: items,
            otros: otros
        }
        return facturacion
    }

    function generarFactura() {

        setLoading(true)

        setMensaje("Generación de la factura en curso")

        let payload = getPayload()

        axios({
            url: process.env.REACT_APP_API_URL + '/facturaManual',
            method: 'POST',
            data: payload,
            timeout: 90000,
            responseType: 'blob',
            auth: {
                username: localStorage.getItem("email") || "",
                password: localStorage.getItem("password") || "",
            }


        }).then((response) => {
            setMensaje("Descargando factura...")
            setLoading(false)
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            var contentDisposition = response.headers['content-disposition'];
            var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setMensaje("Factura descargada correctamente")
        }).catch(async (error) => {
            setLoading(false)
            try {
                let responseObj = await error.response.data.text();
                setMensaje("(error) " + JSON.parse(responseObj).error)
            }
            catch (e) {
                setMensaje("(error) " + error)
            }
        })
    }

    type facturacion = {
        razon_social: string,
        nif: string,
        direccion: string,
        numero: string,
        cp: string,
        municipio: string,
        provincia: string,
        numero_factura: number,
        items: item[]
        otros: otro[]

    }
    type item = {
        referencia_catastral: string,
        tipo_edificio: string,
        precio: string,
        tasa: string,
        previopost: boolean
        tasaprevio: string,
    }
    type otro = {
        descripcion: string,
        precio: string,
    }


    const masNumero = () => {
        if (numero === undefined) {
            setNumero(1)
        }
        else {
            setNumero(numero + 1)
        }
    }

    const menosNumero = () => {
        if (numero === undefined) {
            setNumero(0)
        }
        else {
            if (numero === 0) {
                return
            }
            else {
                setNumero(numero - 1)
            }
        }
    }


    const masNumeroOtros = () => {
        if (numeroOtros === undefined) {
            setNumeroOtros(1)
        }
        else {
            setNumeroOtros(numeroOtros + 1)
        }
    }

    const menosNumeroOtros = () => {
        if (numeroOtros === undefined) {
            setNumeroOtros(0)
        }
        else {
            if (numeroOtros === 0) {
                return
            }
            else {
                setNumeroOtros(numeroOtros - 1)
            }
        }
    }


    return (
        <Form
            {...layout}
            form={form}
            name="control-hooks"
            style={{ maxWidth: 1200 }}
            autoComplete="on"
            onFinish={generarFactura}
        >
            <Show
                title="Generar factura manualmente"
                headerProps={{
                    backIcon: false,
                }}
                headerButtons={<></>}
            >
                <Title level={5}>{"Datos de facturación:"}</Title>
                <Row>
                    <Col span={12}>
                        <Form.Item name="razonsocialfacturacion" label="Razon social" rules={[{ required: true, message: "Razon social requerida" }]}>
                            <Input ></Input>
                        </Form.Item></Col>
                    <Col span={12}>
                        <Form.Item name="niffacturacion" label="NIF/CIF" style={{ marginLeft: 15 }} rules={[
                            {
                                required: true,
                                message: '',
                            },
                            () => ({
                                validator(_, value) {
                                    if (isValidNaturalPersonNif(value) || isValidLegalEntityNif(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('NIF/CIF no correcto'));
                                },
                            }),
                        ]}>
                            <Input style={{ width: 200 }}></Input>
                        </Form.Item></Col>

                </Row>
                <Direccion tipo="facturacion" required={false} />
                <Form.Item name="numerofactura" label="Número de factura" rules={[{ required: true, message: "Número de factura requerido" }]}>
                    <Input style={{ width: 100 }} />
                </Form.Item>

            </Show>

            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Row>
                    <Col span={6}>
                        <Title level={5}>Certificados:</Title>
                    </Col>
                    <Col span={18} >
                        <Space wrap style={{ float: 'right' }}>

                            <Button type="primary" size="small" onClick={menosNumero} >
                                <MenosIcon />
                            </Button>
                            <Button type="primary" size="small" onClick={masNumero}>
                                <MasIcon />
                            </Button>
                        </Space>
                    </Col>

                </Row>
                {Array.from(Array(numero), (e, i) => {
                    return <>
                        <Row>
                            <Col span={7}>
                                <Form.Item name={"referenciacatastral" + i} label="Ref. Catastral" rules={[{ required: true, message: "Referencia catastral requerida" }, { len: 20, message: "La referencia catastral debe tener 20 caracteres" }]}>
                                    <Input width={20} />
                                </Form.Item></Col>
                            <Col span={6}>
                                <Form.Item name={"tipoedificio" + i} label="Tipo de edificio" rules={[{ required: true, message: "Tipo de eficicio requerido" }]}>
                                    <Select showSearch placeholder="Seleccionar tipo de edificio" >
                                        <Option key="Unifamiliar">Unifamiliar</Option>
                                        <Option key="Bloque de viviendas">Bloque de viviendas</Option>
                                        <Option key="Vivienda individual">Vivienda individual</Option>
                                        <Option key="Local">Local</Option>
                                    </Select>
                                </Form.Item></Col>
                            <Col span={3}>
                                <Form.Item name={"precio" + i} label="Precio" initialValue={50} rules={[{ required: true, message: "PRecio requerido" }]}>
                                    <InputNumber min={0} max={10000} decimalSeparator={"."} addonAfter="€" />
                                </Form.Item></Col>

                            <Col span={4}>
                            <Form.Item noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues["previopost" + i] !== currentValues["previopost" + i];
                                    }}
                                >
                                    {({ getFieldValue }) => 
                                        getFieldValue("previopost" + i) ? (
                                            <Form.Item name={"tasaprevio" + i} label="Tasa (Previo)" rules={[{ required: true, message: "Tasa requerida" }]}>
                                                <InputNumber min={0} max={10000} decimalSeparator={"."} addonAfter="€" />
                                            </Form.Item>
                                        ) : null
                                    }
                                </Form.Item>
                                <Form.Item name={"tasa" + i} label="Tasa (Post)" rules={[{ required: true, message: "Tasa requerida" }]}>
                                    <InputNumber min={0} max={10000} decimalSeparator={"."} addonAfter="€" />
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item name={"previopost" + i} label="Previo/Post" rules={[{ required: false }]} valuePropName="checked" >
                                    <Checkbox></Checkbox>
                                </Form.Item>
                            </Col>


                        </Row>
                    </>
                })}

            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Row>
                    <Col span={6}>
                        <Title level={5}>Otros articulos:</Title>
                    </Col>
                    <Col span={18} >
                        <Space wrap style={{ float: 'right' }}>

                            <Button type="primary" size="small" onClick={menosNumeroOtros} >
                                <MenosIcon />
                            </Button>
                            <Button type="primary" size="small" onClick={masNumeroOtros}>
                                <MasIcon />
                            </Button>
                        </Space>
                    </Col>

                </Row>
                {Array.from(Array(numeroOtros), (e, i) => {
                    return <>
                        <Row>
                            <Col span={18}>
                                <Form.Item name={"descripcion" + i} label="Concepto" rules={[{ required: true, message: "Concepto requerido" }]}>
                                    <Input width={50} />
                                </Form.Item></Col>
                            <Col span={4}>
                                <Form.Item name={"preciootros" + i} label="Precio" initialValue={50} rules={[{ required: true, message: "PRecio requerido" }]}>
                                    <InputNumber min={0} max={10000} decimalSeparator={","} addonAfter="€" />
                                </Form.Item></Col>

                        </Row>
                    </>
                })}

            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={5}>{"Estado: " + mensaje}</Title>
                <Divider />
                <Form.Item  >
                    <Button loading={loading} id="generarButton" type="primary" htmlType="submit" >
                        Generar
                    </Button>
                </Form.Item>
            </Show>
        </Form>

    );
};
