
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Show } from "@refinedev/antd";

import { Typography, Form, Input, Select, Button, Row, Col, Upload, UploadProps, message, UploadFile, Checkbox, InputNumber, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import layout from "antd/es/layout";
import logo from "../../images/logo.svg"
import * as Sentry from "@sentry/react";

import { DefaultOptionType } from "antd/es/select";
import { RcFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import axios from "axios";
import { Helmet } from "react-helmet";
import { fetchMunicipios, fetchProvincias, fetchVerificarRC } from "catastro/functions";
import Direccion from "components/direccion";
import React, { createContext, useEffect } from "react";
import { Link, generatePath, redirect, useNavigate, useParams } from "react-router-dom";
import { isValidNaturalPersonNif, isValidLegalEntityNif } from "nif-dni-nie-cif-validation";
import { fetchCliente } from "API/functions";


const { Title, Text } = Typography;
const { Option } = Select;

export const Solicitud: React.FC = () => {
    type ValidationStatus = "success" | "error"
    const [form] = Form.useForm();
    let navigate = useNavigate();
    const [provincias, setProvincias] = React.useState<string[]>([])
    const [municipios, setMunicipios] = React.useState<string[]>([])
    const [enviando, setEnviando] = React.useState<boolean>(false)
    const [display, setDisplay] = React.useState<string>("none")
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [fileList2, setFileList2] = React.useState<UploadFile[]>([]);
    const [direccion, setDireccion] = React.useState<string>()
    const [confirmado, setConfirmado] = React.useState<boolean>(false)
    const [usarDatosCliente, setUsarDatosCliente] = React.useState<boolean>(true)
    const [urgente, setUrgente] = React.useState<boolean>(false)
    const [cp, setCp] = React.useState<boolean>(false)
    const [cpstring, setCpstring] = React.useState<string>("")
    const [textoBoton, settextoBoton] = React.useState<string>("Confirmar")
    const [prerellenado, setPrerellenado] = React.useState<boolean>(false)
    let { id } = useParams();
    let { referido } = useParams();
    useEffect(() => {
        fetchProvincias().then(provincias => setProvincias(provincias))

        // si se da ID automaticamente rellenar los datos
        if (id){
            fetchCliente(id).then(result => {
                form.setFieldsValue({
                    razonsocialfacturacion: result.data.razon_social,
                    niffacturacion: result.data.nif,
                    numerofacturacion: result.data.numero,
                    codigopostalfacturacion: result.data.codigo_postal,
                    municipiofacturacion: result.data.ciudad,
                    provinciafacturacion: result.data.provincia,
                    callefacturacion: result.data.direccion,
                })
                setPrerellenado(true)


            }).catch(error => {
                console.log(error)
            })
        }
        
    }, [])

    const onProvinciaChange = (value: string) => {
        fetchMunicipios(value).then(municipios => setMunicipios(municipios))
    };
    const verificarDatos = (values: any) => {
        fetchVerificarRC(form.getFieldValue("provincia"), form.getFieldValue("municipio"), form.getFieldValue("referenciacatastral")).then(result => {
            setDisplay("block")
            if (result.error) {
                setDireccion(result.msg)
                document.getElementById("comprobartexto")!.removeAttribute("hidden")
                document.getElementById("botonconfirmar")!.setAttribute("hidden", "true")
            } else {
                setDireccion(result.msg)

                document.getElementById("comprobartexto")!.removeAttribute("hidden")
                if (!(result.cpdetected)) {
                    setCp(true)
                    document.getElementById("comprobarcp")!.removeAttribute("hidden")
                    document.getElementById("cp")!.removeAttribute("hidden")
                } else {
                    document.getElementById("botonconfirmar")!.removeAttribute("hidden")
                }

            }
        })
    };
    const props: UploadProps = {
        name: 'file',
        multiple: true,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList((preFileList) => [...preFileList, file]);
            return false;
        },
        fileList: fileList,
    };

    const propsDNI: UploadProps = {
        name: 'fileDNI',
        multiple: false,
        maxCount: 1,
        onRemove: () => {
            setFileList2([]);
        },
        beforeUpload: (file) => {
            setFileList2([file]);
            return false;
        },
        accept: ".jpeg,.jpg,.png,.pdf",
        fileList: fileList2,
    };

    const medidasValidation = () => {
        if (form.getFieldValue("cp")?.length === 5) {
            document.getElementById("botonconfirmar")!.removeAttribute("hidden")
            setCpstring(form.getFieldValue("cp"))
        } else {
            document.getElementById("botonconfirmar")!.setAttribute("hidden", "true")
        }
    }

    const onFinish = (values: any) => {
        verificarDatos(values)

    };

    const changeUsarDatosCliente = (e: any) => {
        setUsarDatosCliente(e.target.checked)
    }

    const changeUrgente = (e: any) => {
        setUrgente(e.target.checked)
    }

    const onConfirmar = (values: any) => {
        if (confirmado) {
            setConfirmado(false)
            settextoBoton("Confirmar")
        } else {
            setConfirmado(true)
            settextoBoton("Cambiar dirección")
        }
    };

    const onEnviar = (values: any) => {
        if (!(confirmado)) {
            return
        }
        const payload: Payload = {
            referido : referido || '',
            provincia: form.getFieldValue("provincia"),
            urgente: urgente,
            municipio: form.getFieldValue("municipio"),
            referenciacatastral: form.getFieldValue("referenciacatastral"),
            cp: form.getFieldValue("cp"),
            datos_cliente: {
                nombre: form.getFieldValue("nombrecliente"),
                apellido1: form.getFieldValue("apellido1cliente"),
                apellido2: form.getFieldValue("apellido2cliente"),
                nif: form.getFieldValue("nifcliente"),
            },
            datos_facturacion: {
                razon_social: form.getFieldValue("razonsocialfacturacion"),
                nif: form.getFieldValue("niffacturacion"),
                calle: form.getFieldValue("callefacturacion"),
                numero: '' + form.getFieldValue("numerofacturacion"),
                cp: '' + form.getFieldValue("codigopostalfacturacion"),
                municipio: form.getFieldValue("municipiofacturacion"),
                provincia: form.getFieldValue("provinciafacturacion"),
                usarDatosCliente: usarDatosCliente && !prerellenado,
            },
            datos_contacto: {
                nombre: form.getFieldValue("nombrecontactoempresa"),
                telefono: '' + form.getFieldValue("telefonoempresa"),
                email: form.getFieldValue("emailempresa"),
                cif: form.getFieldValue("cifempresa"),
            },
            tipoedificio: form.getFieldValue("tipodeedificio"),
            comentarios: form.getFieldValue("comentarios")
        }
        const formData = new FormData();
        formData.append("userData", JSON.stringify(payload))
        fileList.forEach((file) => {
            formData.append('files[]', file as RcFile);
        });
        fileList2.forEach((file) => {
            formData.append('delegacion[]', file as RcFile);
        });

        setEnviando(true)

        axios({
            url: process.env.REACT_APP_API_URL + '/solicitud',
            method: 'POST',
            data: formData,
            timeout: 90000,
            responseType: 'blob',
        }).then((response) => {
            setEnviando(false)
            const config = {
                title: 'Solicitud realizada con éxito',
                content: (
                    <>
                        <br></br>
                        <p>Estamos procensando tu solicitud, en caso de tener alguna duda nos pondremos en contacto contigo.</p>
                        <p>Se ha enviado un correo de confirmación a la dirección de correo electrónico indicada.</p>
                        <br></br>
                        <p>En caso de tener alguna duda contacta con nosotros via email especificando la referencia catastral: {form.getFieldValue("referenciacatastral")}</p>
                    </>
                ),
                centered: true,
                okText: "Rellenar otra solicitud",
                onOk: () => {
                    window.scrollTo(0, 0)
                    window.location.reload();
                }
            };
            modal.success(config);

        }).catch(async (error) => {
            Sentry.setExtra('error', error)
            Sentry.captureException(error);
            
            //Reintentando sin imagenes (por si el error es de no existir imagenes)
            formData.delete("files[]")
            formData.delete("delegacion[]")
            axios({
                url: process.env.REACT_APP_API_URL + '/solicitud',
                method: 'POST',
                data: formData,
                timeout: 90000,
                responseType: 'blob',
            }).then((response) => {
                setEnviando(false)
                const config = {
                    title: '¡Atención! Te contactaremos en breve',
                    content: (
                        <>
                            <br></br>
                            <p>Hemos recibido tu solicitud pero ha habido un problema procesando las fotos y videos.</p>
                            <p>Nos pondremos en contacto contigo en breves, mientras tanto puedes enviarnos las fotos y videos via email a contacto@certinae.es especificando la referencia catastral.</p>
                            <p>Se ha enviado un correo de confirmación a la dirección de correo electrónico indicada.</p>
                            <br></br>
                            <p>En caso de tener alguna duda contacta con nosotros via email especificando la referencia catastral: {form.getFieldValue("referenciacatastral")}</p>
                        </>
                    ),
                    centered: true,
                    okText: "Rellenar otra solicitud",
                    onOk: () => {
                        window.scrollTo(0, 0)
                        window.location.reload();
                    }
                };
                modal.warning(config);
    
            }).catch(async (error) => {
                setEnviando(false)
                Sentry.setExtra('error', error)
                Sentry.captureException(error);
    
                //Reintentando sin imagenes (por si el error es de no existir imagenes)
                
                const configError = {
                    title: 'Se ha producido un error',
                    content: (
                        <>
                            <br></br>
                            <p>Se ha producido un error al procesar tu solicitud.</p>
                            <p>Prueba unos minutos y vuelve a intentarlo.</p>
                            <br></br>
                            <p>Si el error persiste porfavor ponte en contacto con nosotros en contacto@certinae.es para asistirte lo antes posible. </p>  
                            <p>Si es urgente puedes solicitar el certificado directamente por email.</p>
                            <p> Disculpa las molestias.</p>
                        </>
                    ),
                    centered: true,
                    okText: "Cerrar",
                    onOk: () => {
                        instance.destroy();
                    }
                };
                const instance = modal.error(configError);
            })

        })

    };

    type Payload = {
        provincia: string
        municipio: string
        referenciacatastral: string
        cp: string
        datos_cliente: {
            nombre: string
            apellido1: string
            apellido2: string
            nif: string
        }
        datos_facturacion: {
            razon_social: string
            nif: string
            calle: string
            numero: string
            cp: string
            municipio: string
            provincia: string
            usarDatosCliente: boolean
        }
        datos_contacto: {
            nombre: string
            telefono: string
            email: string
            cif: string
        }
        tipoedificio: string
        comentarios: string
        referido: string
        urgente : boolean

    }

    const [modal, contextHolder] = Modal.useModal();

    return (
        <><Helmet>
            <meta charSet="utf-8" />
            <title>Solicitud de CEE - certinae SL</title>
            <meta property="og:title" content="Solicitud de CEE - certinae SL" />
        </Helmet>
        <Form
            {...layout}
            form={form}
            name="control-hooks"
            style={{ maxWidth: 1000, margin: "auto", marginTop: "100px" }}
            autoComplete="on"
            onFinish={onEnviar}
        >
                <img src={logo} width="200px" alt="Logo" style={{ float: 'right' }} />
                <Show
                    title="Solicitud de CEE"
                    headerProps={{
                        backIcon: false,
                    }}
                    headerButtons={<></>}
                    breadcrumb={<></>}
                >
                    <Title level={3}>Localización del inmueble</Title>
                    <Title level={5}>Provincia</Title>
                    <Form.Item name="provincia" label="" rules={[{ required: true, message: "Provincia requerido" }]}>
                        <Select showSearch
                            placeholder="Seleccionar provincia"
                            onChange={onProvinciaChange}
                            allowClear
                            disabled={confirmado}
                        >
                            {provincias.map((provincia) => (
                                <Option key={provincia} value={provincia}>{provincia}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Title level={5}>Municipio</Title>
                    <Form.Item name="municipio" label="" rules={[{ required: true, message: "Municipio requerido" }]}>
                        <Select showSearch
                            placeholder="Seleccionar municipio"
                            allowClear
                            disabled={confirmado}
                        >
                            {municipios.map((municipio) => (
                                <Option key={municipio} value={municipio}>{municipio}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Title level={5}>Referencia catastral</Title>
                    <Form.Item name="referenciacatastral" label="" rules={[{ required: true, message: "Referencia catastral requerida" }, { len: 20, message: "La referencia catastral debe tener 20 caracteres" }]}>
                        <Input disabled={confirmado} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={onFinish} disabled={confirmado}>
                            Verificar
                        </Button>

                    </Form.Item>
                    <div style={{ display: display }}>
                        <Title id="comprobartexto" hidden level={5}>Comprobar dirección</Title>
                        <Form.Item name="direccion">
                            <Text>{direccion}</Text>
                        </Form.Item>
                        <Title id="comprobarcp" hidden level={5}>No se pudo detectar el código postal. Introducir:</Title>
                        <Form.Item name="cp">
                            <Input style={{ width: '20%' }} onChange={medidasValidation} maxLength={5} type="number" hidden id="cp" disabled={confirmado}></Input>
                        </Form.Item>
                        <Form.Item>
                            <Button id="botonconfirmar" onClick={onConfirmar} type="primary" hidden>
                                {textoBoton}
                            </Button>
                        </Form.Item>
                    </div>
                </Show>
                <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                    <Title level={3}>Datos del inmueble</Title>
                    <Title level={5}>Tipo de edificio</Title>
                    <p> "Unifamiliar" en caso de que vivienda no se encuentre en un bloque de edificios, de lo contrario seleccionar "Vivienda individual"</p>
                    <Form.Item name="tipodeedificio" label="" initialValue="Unifamiliar" rules={[{ required: true, message: "Tipo de eficicio requerido" }]}>
                        <Select showSearch placeholder="Seleccionar tipo de edificio">
                            <Option key="Unifamiliar">Unifamiliar</Option>
                            {/* <Option key="Bloque de viviendas">Bloque de viviendas</Option> */}
                            <Option key="Vivienda individual">Vivienda individual</Option>
                            <Option key="Local">Local</Option>
                        </Select>
                    </Form.Item>
                    <Title level={5}>Fotos y videos del inmueble</Title>
                    Imágenes de todas las ventanas y puertas de la vivienda, fotografías de las instalaciones y una fotografía general de la fachada del edificio.
                    <br></br>
                    <br></br>
                    <Form.Item name="archivosinmueble" label="" rules={[
                        {
                            required: true,
                            message: '',
                        },
                        () => ({
                            validator(_, value) {
                                if (fileList.length >= 1) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Al menos 1 archivo es necesario'));
                            },
                        }),
                    ]}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Haz click aqui, o arrastra los archivos a este area</p>
                            <p className="ant-upload-hint">
                                Sube las fotos y los videos del inmueble que sean necesarios para la creación del CEE.
                            </p>
                        </Dragger>
                    </Form.Item>
                    <p>Máximo 100MB</p>
                </Show>
                <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                    <Title level={3}>Datos del propietario</Title>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="nombrecliente" initialValue="" label="Nombre" rules={[{ required: true, message: "Nombre requerido" }]}>
                                <Input></Input>
                            </Form.Item></Col>
                        <Col span={12}>
                            <Form.Item name="nifcliente" initialValue="" label="NIF" style={{ marginLeft: 15 }} rules={[{
                                required: true, message: "NIF requerido", validator: (_, value) => {
                                    if (isValidNaturalPersonNif(value)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('NIF no válido');
                                    }
                                }
                            }]}>
                                <Input style={{ width: 200 }}></Input>
                            </Form.Item></Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="apellido1cliente" initialValue="" label="Primer apellido" rules={[{ required: true, message: "Apellido requerido" }]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="apellido2cliente" initialValue="" label="Segundo apellido" style={{ marginLeft: 15 }} rules={[{ required: true, message: "Apellido requerido" }]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item name="dnipropietario" initialValue="" label="Imagen del DNI/NIE" rules={[
                            {
                                required: true,
                                message: '',
                            },
                            () => ({
                                validator(_, value) {
                                    if (fileList2.length == 1) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('La foto del DNI/NIE es necesaria'));
                                },
                            }),
                        ]}>
                            <Upload {...propsDNI}>
                                <Button icon={<UploadOutlined />}>Seleccionar</Button>
                            </Upload>
                        </Form.Item>
                    </Row>
                    <Row>
                        <p>Formatos admitidos: .png, .jpg, .jpeg, .pdf</p>

                    </Row>
                </Show>
                <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                    <Title level={3}>Datos de facturación</Title>
                    <Row hidden={prerellenado}>
                        <Checkbox checked={usarDatosCliente} onChange={changeUsarDatosCliente} style={{ marginBottom: 15 }}>Usar datos del propietario.</Checkbox>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="razonsocialfacturacion" initialValue="" label="Razon social" rules={[{ required: !usarDatosCliente, message: "Razon social requerida" }]}>
                                <Input name="razonsocialfacturacion" disabled={usarDatosCliente}></Input>
                            </Form.Item></Col>
                        <Col span={12}>
                            <Form.Item name="niffacturacion" initialValue="" label="NIF/CIF" style={{ marginLeft: 15 }} rules={[
                                {
                                    required: !usarDatosCliente,
                                    message: '',
                                },
                                () => ({
                                    validator(_, value) {
                                        if (usarDatosCliente) {
                                            return Promise.resolve();
                                        }
                                        if (isValidNaturalPersonNif(value) || isValidLegalEntityNif(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('NIF/CIF no correcto'));
                                    },
                                }),
                            ]}>
                                <Input disabled={usarDatosCliente} style={{ width: 200 }}></Input>
                            </Form.Item></Col>

                    </Row>
                    <Direccion tipo="facturacion" required={usarDatosCliente} />
                </Show>
                <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                    <Title level={3}>Datos de contacto</Title>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="nombrecontactoempresa" initialValue="" label="Nombre de contacto" rules={[{ required: true, message: "Nombre de contacto requerido" }]}>
                                <Input></Input>
                            </Form.Item></Col>
                        <Col span={12}>
                            <Form.Item name="telefonoempresa" initialValue="" label="Teléfono" style={{ marginLeft: 15 }} rules={[{ required: true, message: "Telefono requerido", type: 'number' }]}>
                                <InputNumber style={{ width: 200 }}></InputNumber>
                            </Form.Item></Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="emailempresa" initialValue="" label="Email" rules={[{ required: true, message: "Email requerido", type: "email" }]}>
                                <Input></Input>
                            </Form.Item></Col>
                        <Col span={12}>
                            <Form.Item name="cifempresa" initialValue="" style={{ marginLeft: 15 }} label="NIF/CIF" rules={[{
                                required: true, message: "NIF/CIF requerido", validator: (_, value) => {
                                    if (isValidNaturalPersonNif(value) || isValidLegalEntityNif(value)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('NIF/CIF no válido');
                                    }
                                }
                            }]}>
                                <Input style={{ width: 200 }}></Input>
                            </Form.Item></Col>
                    </Row>
                </Show>
                <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                    <Title level={3}>Urgente</Title>
                    <p>Si necesita el certificado con urgencia, marque la casilla a continuación. Se aplicará un coste adicional de <strong>50 € + IVA.</strong> El certificado se emitirá en un plazo de 1 día laborable <strong>una vez tengamos toda la información necesaria.</strong></p>
                    <Row>
                        <Col span={12}>
                        <Checkbox checked={urgente} onChange={changeUrgente} style={{ marginBottom: 15 }}>Este certificado es urgente.</Checkbox></Col>

                    </Row>
                </Show>
                <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                    <Title level={3}>Otra información</Title>
                    <Form.Item name="comentarios" initialValue="" style={{ marginLeft: 15 }} label="">
                        <TextArea rows={4} placeholder="Comentarios" />
                    </Form.Item>


                </Show>
                <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                    {/* <Form.Item name="checkpermiso"  label="" rules={[{ required: true, message: "Permiso requerido" }]}>
        <Checkbox>Tengo permiso explicito del propietario del inmueble para solicitar y registrar el certificado de eficiencia energético.</Checkbox>
    </Form.Item>
    <Form.Item name="checkterminos"  label="" rules={[{ required: true, message: "Permiso requerido" }]}>
        <Checkbox>Acepto los terminos y condiciones del servicio.</Checkbox>
    </Form.Item> */}
                    <Button type="primary" htmlType="submit" disabled={!confirmado} loading={enviando} style={{ float: 'right' }}>
                        Enviar
                    </Button>
                    {contextHolder}
                </Show>

            </Form></>

    );
};


